'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    dialog = require('../../dialog'),
    util = require('../../util');


/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            tooltip.init();
            $('#find-retailer').on('click', function(e) {
                e.preventDefault();
                dialog.open({
                    url: $(this).attr('href'),
                    options: {
                        dialogClass: 'find-retailer'
                    }
                });
            });
        }
    });
};

/**
 * @description update product tabs content with new variant from href, load new content to #product-info panel
 * @param {String} href - url of the new product variant
 **/
var updateTabsContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax2',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-tabs')
    });
};
/**
 * @description update product feature slider with new variant from href
 * @param {String} href - url of the new product variant
 **/
var updateFeatureSlider = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax3',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#featuresHolder')
    });
};

module.exports = function () {
    var $pdpMain = $('#pdpMain');
    // hover on swatch - should update main image with swatch image
    $pdpMain.on('mouseenter mouseleave', '.swatchanchor', function () {
        var largeImg = $(this).data('lgimg'),
            $imgZoom = $pdpMain.find('.main-image'),
            $mainImage = $pdpMain.find('.primary-image');

        if (!largeImg) { return; }
        // store the old data from main image for mouseleave handler
        $(this).data('lgimg', {
            hires: $imgZoom.attr('href'),
            url: $mainImage.attr('src'),
            alt: $mainImage.attr('alt'),
            title: $mainImage.attr('title')
        });
        // set the main image
        image.setMainImage(largeImg);
    });

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail #product-content .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        updateContent(this.href);
        updateTabsContent(this.href);
        updateFeatureSlider(this.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function () {
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());
        updateTabsContent($(this).val());
        updateFeatureSlider($(this).val());
    });

	document.onreadystatechange = function() {
		if ($(".y-rich-snippet-script").length>0) {
			var data = JSON.parse($(".y-rich-snippet-script")[0].innerText);		
			var arElem = document.querySelector('#AggregateRatingPlaceholder');
			arElem.innerHTML = '<div itemprop="aggregateRating" itemtype="http://schema.org/AggregateRating" itemscope><meta itemprop="ratingValue" content="' + data.aggregateRating.ratingValue + '"><meta itemprop="reviewCount" content="' + data.aggregateRating.reviewCount + '"></div>';
		}
	};
};

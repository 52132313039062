'use strict';
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable block-scoped-var */

require('intersection-observer');
var crypto = require('crypto');
var _ = require('lodash');

window.dataLayer = window.dataLayer || [];
var io;
var observedImpressions = [];
var reportedImpressions = [];

/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
var events = {
    account: function () {
        $('#dwfrm_login').on('submit', function () {
            pushEvent('myAccountComplete', 'User Action', 'Login Form Submitted', 'Account page');
        });
        var timeout = false;
        $('button[name=dwfrm_ordertrack_findorder]').on('click', function () {
            if (!timeout) {
                pushEvent('checkOrder', 'User Action', 'Order Track Button click', 'Account page');
                timeout = true;
                window.setTimeout(function () { timeout = false; }, 1000);
            }
        });
        if ($('#dwfrm_login').length && $('#dwfrm_login').data('gtm-user-data') == 'guest') {
            pushEvent('myAccountStart', 'Page Load', 'Guest User on Login Page ', 'Account page');
        }
    },
    cart: function () {
        $('[name$=_deleteProduct]').on('click', function () {
            removeFromCart($.parseJSON($(this).attr('data-gtmdata')), $(this).attr('data-gtmqty'));
        });

        $('.button.purchase.to-checkout').on('click', function () {
            initiateCheckout();
        });
    },
    checkout: function () {
        $('[name$=_deleteProduct]').on('click', function () {
            removeFromCart($.parseJSON($(this).attr('data-gtmdata')), $(this).attr('data-gtmqty'));
        });
    },
    compare: function () {},
    product: function () {
        /*
        $('#add-to-cart').on('click', function () {
            addToCart($.parseJSON($(this).attr('data-gtmdata')), $(this).parent().prev('div').find('[name=Quantity]').val());
        });
        */

        // Facebook
        $('a.facebook.share-icon').on('click', function () {
            var shortProductData = $.parseJSON($(this).attr('data-gtm-product-data')),
                obj = {
                    'event': 'facebookShare',
                    'product_shared': shortProductData.product_shared,
                    'product_id_shared': shortProductData.product_id_shared
                };
            dataLayer.push(obj);
        });

        // Twitter
        $('a.twitter.share-icon').on('click', function () {
            var shortProductData = $.parseJSON($(this).attr('data-gtm-product-data')),
                obj = {
                    'event': 'twitterShare',
                    'product_shared': shortProductData.product_shared,
                    'product_id_shared': shortProductData.product_id_shared
                };
            dataLayer.push(obj);
        });

        // Pinterest
        $('a.pinterest.share-icon').on('click', function () {
            var shortProductData = $.parseJSON($(this).attr('data-gtm-product-data')),
                obj = {
                    'event': 'pinterestShare',
                    'product_shared': shortProductData.product_shared,
                    'product_id_shared': shortProductData.product_id_shared
                };
            dataLayer.push(obj);
        });

        // Email
        $('a.email.share-icon').on('click', function () {
            var shortProductData = $.parseJSON($(this).attr('data-gtm-product-data')),
                obj = {
                    'event': 'emailShare',
                    'product_shared': shortProductData.product_shared,
                    'product_id_shared': shortProductData.product_id_shared
                };
            dataLayer.push(obj);
        });
    },
    search: function () {

    },
    storefront: function () {
        io = new IntersectionObserver(observer, {threshold: 0.5});
        io.POLL_INTERVAL = 1000;
        reinit();
        window.addEventListener('unload', function() {
            // flush immediately
            _reporter();
        });

        $('.homepage-hero-banner a').on('click', function () {
            var $el = $(this);
            if ($el.closest('[data-position]').length>0) {
                dataLayer.push({
                    'event': 'promotionClick',
                    'ecommerce': {
                        'promoClick': {
                            'promotions': [getSlotInfo($el)]
                        }
                    }
                });
            }
        });
        $('.home-content-slot a').on('click', function () {
            var $el = $(this);
            if ($el.closest('[data-position]').length>0) {
                dataLayer.push({
                    'event': 'promotionClick',
                    'ecommerce': {
                        'promoClick': {
                            'promotions': [getSlotInfo($el)]
                        }
                    }
                });
            }
        });
    },
    wishlist: function () {
        $('[name$=_addToCart]').on('click', function () {
            addToCart($.parseJSON($(this).attr('data-gtmdata')), $(this).closest('div').find('[name=Quantity]').val());
        });
    },
    // events that should happen on every page
    all: function () {
        $('.name-link').on('click', function () {
            // productClick($.parseJSON($(this).attr('data-gtmdata')));
        });
        $('.thumb-link').on('click', function () {
            // productClick($.parseJSON($(this).attr('data-gtmdata')));
        });
        $('.has-sub-menu').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', $(this).html());
        });
        $('.primary-logo').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', 'Home Link');
        });
        $('form[id="newsletter-signup-footer"]').on('submit', function () {
            pushEvent('emailFooter', 'User Action', 'Footer Subscribe form Submit', 'Footer');
        });
        $('a#find-retailer').on('click', function () {
            pushEvent('findRetailer', 'User Action', 'Find a Retailer Button', 'PDP');
        });
        $('.giveawayContainer #submit').on('click', function () {
        	var dataLayer = window.dataLayer;
        	var email = document.querySelector('[name="email"]').value;
        	var phone = document.querySelector('[name="Customer Info.Phone Number"]').value;
        	if (email && email != '' && phone && phone != '') {
	        	dataLayer.push({
	        		event: "giveaway_form_submit",
	        		email: hash(email),
	        		phone_number: hash(phone),
	        		external_id: hash(baseDataLayer.sessionId)
	        	});
        	}
        });
    }
};
function hash(str) {
	return crypto.createHash('sha256').update(str).digest('hex');
};
function getSlotInfo(element) {
    var $el = $(element);
    var info = {
        'id': $el.closest('[data-position]').length>0 ? $el.closest('[data-position]').attr('id') : '',
        'name':     $el.closest('[data-content-id]').length>0 ? $el.closest('[data-content-id]').attr('data-content-id') : '',
        'creative': $el[0].innerText ? $el[0].innerText : 'image',
        'position': $el.closest('[data-position]').length>0 ? $el.closest('[data-position]').attr('data-position') : ''
    };
    return info;
}

function reinit() {
    var i;

    // product impression observers
    var productTiles = document.querySelectorAll('.home-content-slot a:not(.ignore)');
    for (i=0; i < productTiles.length; i++) {
        var tile = productTiles[i];
        if (typeof tile !== 'undefined') {
            io.observe(tile);
        }
    }
    productTiles = document.querySelectorAll('.homepage-hero-banner a:not(.ignore)');
    for (i=0; i < productTiles.length; i++) {
        tile = productTiles[i];
        if (typeof tile !== 'undefined') {
            io.observe(tile);
        }
    }
}

function _reporter(target, cb) {
    var i;
    if (observedImpressions.length === 0) {
        return;
    }

    var dataLayer = window.dataLayer;
    var _dataLayer = {
        'event': 'promoView',
        'ecommerce': {
            'promoView': {
                'promotions': []
            }
        }
    };

    var impressions = observedImpressions.map(function(element) {
        return getSlotInfo(element);
    });
    Array.prototype.push.apply(reportedImpressions, observedImpressions);
    observedImpressions = [];

    for (i = 0; i < impressions.length; i++) {
        var impression = impressions[i];
        var promotion = {
            'id': impression.id,
            'name': impression.name,
            'creative': impression.creative,
            'position': impression.position
        }
        _dataLayer.ecommerce.promoView.promotions.push(promotion);
    }

    if (cb) {
        _dataLayer.eventCallback = cb;
    }

    dataLayer.push(_dataLayer);
}
var reporter = _.debounce(_reporter, 3000);

function observer(entries, io) {
    for (var i = 0; i < entries.length; i++){
        var entry = entries[i];
        if (entry.intersectionRatio <= 0)  {
            continue;
        }
        io.unobserve(entry.target);

        if (reportedImpressions.indexOf(entry.target) === -1 &&
            observedImpressions.indexOf(entry.target) === -1) {
            observedImpressions.push(entry.target);
        }
    }
    reporter();
}

/**
 * @param {Object} productObject The product data
 * @description gets the data for a product click
 */
function productClick (productObject) {
    var obj = {
        'event': 'productClick',
        'ecommerce': {
            'click': {
                'actionField': {'list': 'SearchResults'},
                'products': []
            }
        }
    };
    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {String} quantity
 */
function addToCart (productObject, quantity, eventTime) {
    var quantityObj = {'quantity': quantity},
        obj = {
            'event': 'addToCart',
            'event_id': 'add_to_cart' + '_' + baseDataLayer.sessionId + '_' + eventTime,
            'ecommerce': {
                'add': {
                    'products': []
                }
            }
        };
    obj.ecommerce.add.products.push($.extend(productObject,quantityObj));
    dataLayer.push(obj);
}

/**
 *
 * @param {Object} productObject
 * @param {String|Number} quantity
 */
function removeFromCart (productObject, quantity) {
    var quantityObj = {'quantity': quantity},
        obj = {
            'event': 'removeFromCart',
            'ecommerce': {
                'remove': {
                    'products': []
                }
            }
        };
    obj.ecommerce.remove.products.push($.extend(productObject,quantityObj));
    dataLayer.push(obj);
}

function initiateCheckout() {
    var eventTime = Math.round(Date.now() / 1000); // current time in seconds
    var obj = {
        'event': 'initiateCheckout',
        'event_id': 'initiate_checkout' + '_' + baseDataLayer.sessionId + '_' + eventTime
    };
    dataLayer.push(obj);
    var url = Urls.initiateCheckout;
    $.ajax({
        url: url,
        method: 'POST',
        data: '&eventTime=' + eventTime
    });
}
/**
 * goToCheckout gtm tracker
 */
function goToCheckout() {
    var products = [];
    $('[name$=_deleteProduct]').each(function (k, el) {
        products[k] = $(el).data('gtmdata');
    });
    dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
            'checkout': {
                'actionField': {
                    'step': 1
                },
                products: products
            }
        }
    })
}

/**
 * @description Convenience method for creating a click event.
 * @param {String} event
 * @param {String} eventCategory
 * @param {String} eventAction
 * @param {String} eventLabel
 */
function pushEvent (event, eventCategory, eventAction, eventLabel) {
    dataLayer.push({
        'event': event,
        'eventCategory': eventCategory,
        'eventAction': eventAction,
        'eventLabel': eventLabel
    });
}

/**
 * @description Initialize the tag manager functionality
 * @param {String} nameSpace The current name space
 */
exports.addToCart = addToCart;
exports.init = function (nameSpace) {
    if (nameSpace && events[nameSpace]) {
        events[nameSpace]();
    }
    events.all();
};

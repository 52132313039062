$(".footer-links .col h3").on('click', function(){
	var thisClicked = $(this);
    var wow = window.outerWidth;
    if(wow > 767){
        var drawer = $(this).parent().find('.desktop');
    } else {
        var drawer = $(this).parent().find('.mobile');
    }
    drawer.slideToggle();
    thisClicked.toggleClass("active");
});

$(".email-submit").on('focus', function(){
	$(".email-submit-override").addClass('outlineVis');
});

$(".email-submit").on('blur', function(){
	$(".email-submit-override").removeClass('outlineVis');
});

var emailSignupForm = $("#footersignup");
emailSignupForm.submit(function(e) {
	e.preventDefault();
	emailSignupForm.find('span.error').hide();
	$("#emailerror").html("");

	var enteredEmail = $("#email-field").val();
    var emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    var isValid = emailRegex.test(enteredEmail);
	if (!emailSignupForm.valid() || !isValid) {
        if (enteredEmail && enteredEmail !== '') {
        	$("#emailerror").html('Please enter a valid email address.');
        }
		return false;
	}

    var enteredSms = $("#phone-field").val();
    var smsRegex = /^1?[0-9]{10}$/;
    var isValid = smsRegex.test(enteredSms.replace(/\D/g, ""));
	if (!isValid) {
        if (enteredSms && enteredSms !== '') {
        	$("#emailerror").html('Please enter a valid phone number.');
            return false;
        }
	}

	/* Remove REST calls
    $.post($(this).data("email-action"), emailSignupForm.serialize() , function(returnData){
        // Do Nothing
    });

    $.post($(this).data("sms-action"), emailSignupForm.serialize() , function(returnData){
        // Do Nothing
    });
	*/
	
	setTimeout(function() {
        $("#footersignup").hide();
        $("#emailconfirm").show();
      }, 700);

    return false;
});

// listrak form listener for listrak to load wrapper
(function(d){if(typeof _ltk=="undefined"){if(document.addEventListener)document.addEventListener("ltkAsyncListener",function(){_ltk_util.ready(d)});else{e=document.documentElement;e.ltkAsyncProperty=0;e.attachEvent("onpropertychange",function(e){if(e.propertyName=="ltkAsyncProperty"){_ltk_util.ready(d)}})}}else{_ltk_util.ready(d)}})(function(){
  
  // sends to listrak - sms subscription point "footerSMS" - email subscription point "Footer"
  _ltk.Signup.New("footerSMS", "phone-field", _ltk.Signup.TYPE.CLICK, "footer-newsletter-submit", "sms");
  _ltk.Signup.New("Website Footer - ChiccoShop", "email-field", _ltk.Signup.TYPE.CLICK, "footer-newsletter-submit", "email");

  // hide and show sms legal text based on input
  var phoneField = document.getElementById("phone-field");
  var smsLegal = document.getElementsByClassName("sms-legal")[1];
  phoneField.addEventListener("input", function(e){
    if (phoneField.value.length > 0) {
      smsLegal.style.display = "block";
    } else {
      smsLegal.style.display = "none";
    }
  });

  // add hyphens to phone field as user types
  phoneField.addEventListener('keyup', function(e){
    if (event.key != 'Backspace' && (phoneField.value.length === 3 || phoneField.value.length === 7)){
    phoneField.value += '-';
    }
  });
});

// prevents user from adding dashes to phone number
function validatePhoneNumber(event) {
  var key = event.charCode || event.keyCode || 0;
  return (key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105));
}


/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    footer = require('./footer'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    searchsuggest = require('./searchsuggest'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    ajax =  require('./ajax'),
    validator = require('./validator'),
    tls = require('./tls'),
    tagmanager = require('./tagmanager');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();
require('picturefill');


//product content slot horizonal-carousels
//added to window for CQuotient, self-executing for fallback
function pdpRecommendationsInit() {
    var $hCarousel = $('.product-listing-1x4 > ul');
    // do not initialize twice
    if ($hCarousel.hasClass('slick-slider')) {
        return;
    }
    $hCarousel.slick({
        slidesToScroll: 1,
        slide: 'li',
        slidesToShow: 3,
        swipeToSlide: true,
        dots: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });
};

function sliderIndex () {
    if ($('.grid-tile[aria-hidden="true"]').length > 0) {
        setTimeout(function(){
            $('.grid-tile[aria-hidden="true"]').find('a, input, button, select, .yotpo-bottomline').attr( 'tabindex', '-1');
        }, 1000);
    }
    if ($('.grid-tile[aria-hidden="false"]').length > 0) {
        setTimeout(function(){
            $('.grid-tile[aria-hidden="false"]').find('a, input, button, select, .yotpo-bottomline').attr( 'tabindex', '0');
        }, 1000);
    }
}

$('.slick-prev, .slick-next').on('click', function (e) {
    sliderIndex(e);
});

function recommendationInitTimer () {
    var timeOut = setTimeout(nextTry, 500),
        secondsCounter = 0,
        flag = true;
    function nextTry() {
        if (!flag){
            return false;
        }
        clearTimeout(timeOut);
        flag = false;
        timeOut = setTimeout(nextTry, 500);
        if (secondsCounter >= 10){
            flag = false;
        } else {
            if ($('.product-listing-1x4 > ul').length > 0) {
                pdpRecommendationsInit();
                flag = false;
            } else {
                secondsCounter = secondsCounter + 1
                flag = true;
            }
        }
        return false;
    }
}

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $('.top-banner .header-search');
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);

    //Print Order receipt
    $('a.print-page').on("click", function () {
        window.print();
        return false;
    });
    
    //ADA keypress action
    $('.toggle').on('keypress', function (e) {
    	e.preventDefault();
        if (e.which == 13) {
        	$(this).click();
        }
        if (e.which == 27) {
        	if ($(this).attr('aria-expanded') == 'true') {
        		$(this).click();
        	}
        }
    });
    
    // add show/hide navigation elements
    $('.secondary-navigation .toggle').on('click', function (e) {
    	e.preventDefault();
        $(this).toggleClass('expanded').next('ul').toggle();
        $(this).focus();
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    $(".topLevel").on('click', function () {
        $(this).toggleClass('expanded').next('.category-refinement').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    $('.privacy-policy, .return-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                dialogClass: 'policy',
                height: 600
            }
        });
    });

    // Minicart focus
    minicart.setFocus();

    // Minicart  close button
    $('#close-minicart-btn').on('click', function() {
        minicart.close();
    });
    //make sure tabinex is 0 for desktop
    if (window.innerWidth > 768 ) {
        $('#navigation a').attr('tabindex', 0 );
    }
    
    if (window.innerWidth <= 1024 ) {
	    if( $('.menu-active').length < 1) {
	    	$('#navigation').css('display', 'none');
	    	$('#main').attr('aria-hidden','false');
	    	$('.main').attr('aria-hidden','false');
	    }
    }
    
    $(window).on('resize',function(){
    	if (window.innerWidth <= 1024 ) {
		    if( $('.menu-active').length < 1) {
		    	$('#navigation').css('display', 'none');
		    	$('#main').attr('aria-hidden','false');
		    	$('.main').attr('aria-hidden','false');
		    }
	    } else {
	    	$('#navigation').css('display', 'block');
	    	$('#main').attr('aria-hidden','true');
	    	$('.main').attr('aria-hidden','true');
	    }
    });
    $(window).on('resize',function(){
    	navIndex();
    });
    
    if( $('.menu-active').length < 1) {
    	$('#navigation').css('display', 'none');
    	$('.hamburger-open').css('display', 'block');
    	$('#main').attr('aria-hidden','false');
    	$('.main').attr('aria-hidden','false');
    	$('.hamburger-close').css('display', 'none');
    } else {
    	$('#navigation').css('display', 'block');
    	$('.hamburger-open').css('display', 'none');
    	$('#main').attr('aria-hidden','true');
    	$('.main').attr('aria-hidden','true');
    	$('.hamburger-close').css('display', 'block'); 	
    }
    
    $('.mobile .menu-utility-user a').on('blur', function() {
    	$('.menu-toggle').focus();
    });
    
    $('body').on('focusout blur swipe', '.mobile .menu-utility-user a', function() {
    	$('.menu-toggle').focus();
    });
    
    if( $('.menu-active').length < 1) {
    	$('.hamburger-open').css('display', 'block');
    	$('#main').attr('aria-hidden','false');
    	$('.main').attr('aria-hidden','false');
    	$('.hamburger-close').css('display', 'none');
    } else {
    	$('.hamburger-open').css('display', 'none');
    	$('#main').attr('aria-hidden','true');
    	$('.main').attr('aria-hidden','true');
    	$('.hamburger-close').css('display', 'block'); 	
    }
    
    $('.mobile .menu-utility-user a').on('blur', function() {
    	$('.menu-toggle').focus();
    });
    
    // main menu toggle
    $('.menu-toggle').on('click', function () {
        $('#wrapper').toggleClass('menu-active');
        $('body').toggleClass('menu-active');
        if( $('.menu-active').length < 1) {
        	$(this).attr('aria-expanded', 'false');
        	$('#navigation').css('display', 'none');
        	$('.hamburger-open').css('display', 'block');
        	$('#main').attr('aria-hidden','false');
        	$('.main').attr('aria-hidden','false');
        	$('.hamburger-close').css('display', 'none');
        } else {
        	$('#navigation').css('display', 'block');
        	$(this).attr('aria-expanded', 'true');
        	$('.hamburger-open').css('display', 'none');
        	$('#main').attr('aria-hidden','true');
        	$('.main').attr('aria-hidden','true');
        	$('.hamburger-close').css('display', 'block'); 	
        }
        $('#navigation a').attr('tabindex')== -1 ? $('#navigation a').attr('tabindex', 0 ) : $('#navigation a').attr('tabindex', -1 );
    });

    //check to see if the viewport is mobile and set tab index for the nav menu items accordingly
    function navIndex() {
        if (window.innerWidth < 768) {
        //If the menu is not active on pageload remove mobile menu items from tabindex
            if ($('.menu-active').length < 1) {
                $("#navigation a").attr('tabindex', '-1');
                $('#main').attr('aria-hidden','false');
                $('.main').attr('aria-hidden','false');
            } else {
            	$("#navigation a").attr('tabindex', '0');
            	$('#main').attr('aria-hidden','true');
            	$('.main').attr('aria-hidden','true');
            }
        //If the menu is activated via toggle, make sure mobile menu items are in the tabindex and if the menu is deactivated remove them from the tabindex
            $('.menu-toggle').on('click', function () {
                if ($('.menu-active').length > 0) {
                    $("#navigation a").attr('tabindex', '0');
                    $('#main').attr('aria-hidden','true');
                    $('.main').attr('aria-hidden','true');
                } else {
                    $("#navigation a").attr('tabindex', '-1');
                    $('#main').attr('aria-hidden','false');
                    $('.main').attr('aria-hidden','false');
                }
            });
            if ($('.pt_checkout').length) {
            	$('#navigation').css('display', 'none');
            }
        } else {
        	if ($('.pt_checkout').length) {
            	$('#navigation').css('display', 'none');
            } else {
        	    $('#navigation').css('display', 'block');
            }
        }
    }
    navIndex();
    $('.menu-category li .menu-item-toggle').on('click', function (e) {
        e.preventDefault();
        var $parentLi = $(e.target).closest('li');
        $parentLi.siblings('li').removeClass('active').find('.menu-item-toggle').removeClass('fa-chevron-up active').addClass('fa-chevron-right');
        $parentLi.toggleClass('active');
        $(e.target).toggleClass('fa-chevron-right fa-chevron-up active');
    });
    $('body').on('click', function(){
        $('.header-menu__nav').hide();
        $('.header-menu, .user-account').attr('aria-expanded', false);
    });
    $('.account-menu-desktop .user-account').on('click', function(e) {
    	e.preventDefault();
    });
    $(document).on('click', '.afterpay-link', function (e) { 
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    $('.header-menu').on('click', function(e) {
        e.stopPropagation();
        $('.header-menu__nav').hide();
        if ($(this).attr('aria-expanded') == "true") {
        	$('.header-menu__nav').hide();
        	$(this).attr('aria-expanded', false);
        } else {
        	$(this).attr('aria-expanded', true);
        }
        if (!$(this).parent().hasClass('account-menu-mobile')){
        	if ($(this).attr('aria-expanded') == "true") {
        		$(this).find('.header-menu__nav').toggle();
        	}
        }
    });
    $('.header-menu').on('keydown', function(e) {
        var code = e.charCode || e.keyCode;
    	if (code === 13) {
            e.preventDefault();
	        if ($(this).attr('aria-expanded') == "true") {
	        	$('.header-menu__nav').hide();
	        	$(this).attr('aria-expanded', false);
	        } else {
	        	$(this).attr('aria-expanded', true);
	        }
	        if (!$(this).parent().hasClass('account-menu-mobile')){
	        	if ($(this).attr('aria-expanded') == "true") {
	        		$(this).find('.header-menu__nav').toggle();
	        	}
	        }
    	}
    });
    
    $('.mini-cart-link').on('keydown', function(e) {
        var code = e.charCode || e.keyCode;
        if (code === 13) {
            $(this).click();
        }
    });

    //customer service menu a11y access
    $('.header-menu.customer-service').on('click keypress', function(e) {
        if(util.a11yClick(e) == true){
            $('.customer-service .header-menu__nav').show();
        }
    });
    // close menu with escape
    $('body').on('click keypress keydown', function(e) {
        var code = e.charCode || e.keyCode;
        if (code === 27) {
            $('.customer-service .header-menu__nav').hide();
        }
    });

    $('.account-menu-desktop').on('keydown', function(e) {
        var code = e.charCode || e.keyCode;
    	if (code === 13) {
            e.preventDefault();
	        if ($(this).attr('aria-expanded') == "true") {
	        	$('.header-menu__nav').hide();
	        	$(this).attr('aria-expanded', false);
	        } else {
	        	$(this).attr('aria-expanded', true);
	        }
	        if (!$(this).parent().hasClass('account-menu-mobile')){
	        	if ($(this).attr('aria-expanded') == "true") {
	        		$(this).find('.header-menu__nav').toggle();
	        	}
	        }
    	}
    });
    
    $('.fake-search-trigger').on('click', function () {
        var element = $('.header-search');
        //not using slideToggle as there are multiply search triggers that can be triggered
        if (element.is(":hidden")) {
            element.slideDown('slow');
            $('.fake-search-trigger').parent().addClass('active');
        } else {
            element.slideUp('slow');
            $('.fake-search-trigger').parent().removeClass('active');
        }

        // hide the user menu when header search is clicked
        var userInfo = $('.user-info.active');
        if (userInfo.length > 0) {
            userInfo.toggleClass('active');
        }
    });

    $('.level-1 > li > a.has-sub-menu').on('mouseenter keypress keydown', function (e) {
        // hide the user menu when hovering over level-1 categories
        var userInfo = $('.user-info.active');
        if (userInfo.length > 0) {
            userInfo.toggleClass('active');
        }
        var unicode=e.keyCode? e.keyCode : e.charCode,
            enterKeyCode = 13,
            tabKeyCode = 9;

        if (unicode === enterKeyCode || util.a11yClick(e) === true) {
            $(this).parent().find('.level-2').addClass('a11y-access');
            $(this).attr('aria-expanded', true);
        }
        if (unicode === tabKeyCode) {
            $(this).trigger('focus');
        }
    });

    // remove a11y-access class and dismiss menu
    $(window).keyup(function (e) {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 9 && $('.a11y-access').length > 0 || code == 27) {
            $('.level-2').removeClass('a11y-access');
            $('.level-1 > li > a.has-sub-menu').attr('aria-expanded', false);
        }
    });


    $('.level-1 > li > a.has-sub-menu').on('click', function (e) {
        e.preventDefault();
        $(this).parent().find('.menu-item-toggle').click();
    })

    // Accordion open/close event handler
    $('.accordion-title').on('click', function () {
        var $item = $(this).parent();
        var $content = $item.find('.accordion-content');
        if ($item.hasClass('is-active')) {
            $content.slideUp();
        } else {
            $content.slideDown();
        }
        $item.toggleClass('is-active');
    });

    //Google Tag Manager
    $('[data-gtm-ename]').on('click', function() {
        var eventName = this.getAttribute('data-gtm-ename'),
            productID = this.getAttribute('data-gtm-productId'),
            productName = this.getAttribute('data-gtm-productName');

        dataLayer.push({
            'event': eventName,
            'productIdAdded': productID,
            'productAdded': productName
        })
    })
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();

    // Make sure that the SVGs work properly in older browsers
    svg4everybody();
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        recommendationInitTimer();
        initializeEvents();
        sliderIndex();

        //Added for paypal implementation
        window.sgDialog = require('./dialog');
        window.sgUtil = require('./util');

        //setup global js for content assets
        if ($('.reusableContent')) {
            if ($(window).width() < 751) {
                $('.movable').each(function(){
                      $(this).insertAfter($(this).closest('.fake-main').find('.placable'));
                  });
              } else {
                  $('.movable').each(function(){
                      $(this).insertAfter($(this).closest('.fake-main').find('.tab-button-container'));
                  });
              }
              $( window ).resize(function() {
                  if ($(window).width() < 751) {
                      $('.movable').each(function(){
                          $(this).insertAfter($(this).closest('.fake-main').find('.placable'));
                      });
                  } else {
                      $('.movable').each(function(){
                          $(this).insertAfter($(this).closest('.fake-main').find('.tab-button-container'));
                      });
                  }
            });
        }
        function clickCircle(el) {
            var _this = el;
            var cIndex = $(_this).index();
            var tabsHolder = $(_this).parents('.tab-content').find('.tab-product-content-container');
            tabsHolder.find('.tab-product-content').removeClass('selected-product');
            tabsHolder.find('.tab-product-content').eq(cIndex).addClass('selected-product');
            $(_this).parent().find('.circle').removeClass('selected-circle');
            $(_this).addClass('selected-circle');
        }

        function contentCarousel(tab) {
            var $hCarousel = $(tab).find('.mobile-slick');
            $hCarousel.slick('unslick');
            $hCarousel.slick({
                slidesToScroll: 1,
                slidesToShow: 4,
                arrows: true,
                swipeToSlide: true,
                dots: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            dots: true
                        }
                    }
                ]
            });
        };

        var dotsChange = true;

        $('.menu-utility-user li a').on('mouseover', function() {
            if (dotsChange) $(this).parent().find('.dots').show();
        });
        $('.menu-utility-user li a').on('mouseout', function() {
            if (dotsChange) $(this).parent().find('.dots').hide();
        });
        $('.mini-cart-total a').on('mouseover', function() {
            if (dotsChange) $(this).parent().find('.dots').show();
        });
        $('.mini-cart-total a').on('mouseout', function() {
            if (dotsChange) $(this).parent().find('.dots').hide();
        });
        $('.menu-utility-user li a').on('click', function() {
            $(this).parent().find('.dots').hide();
            dotsChange = !dotsChange;
        });

        $('.reusableContent .circle').on('click', function() {
            clickCircle(this);
        });

        $(window).on('load', function() {
        	if( $('.step-0').hasClass('active') || $('.step-1').hasClass('active') || $('.step-2').hasClass('active')) {
        		$(window).scrollTop(0);
        	}
        });

        $('.reusableContent .tab').on('click', function () {
            $(this).closest('.fake-main').find('.tab').toggleClass('selected-tab');
            var svgArrows = $(this).closest('.fake-main').find('.tabsvg');
            svgArrows.each(function (i, el) {
                if ($(el).attr("class").indexOf('selected-tab-svg') != -1) {
                    var removedClass = el.getAttribute('class').replace(new RegExp('(\\s|^)' + "selected-tab-svg" + '(\\s|$)', 'g'), '$2');
                    $(el).attr("class", removedClass);
                } else {
                    $(el).attr("class", $(el).attr("class") + " selected-tab-svg");
                }
            });
            $(this).closest('.fake-main').find('.tab-content').toggleClass('selected-tab-content');
            $(this).closest('.fake-main').find('.tab-content').each(function () {
                contentCarousel(this);
            });
        });
        $('.mobile-slick').slick({
            slidesToScroll: 1,
            slidesToShow: 4,
            arrows: true,
            swipeToSlide: true,
            dots: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        dots: true
                    }
                }
            ]
        });
        if ($('.video-slick').length) {
            var videoSlicked = false;
            slickVideos()
            function slickVideos(){
                if(!videoSlicked && $(window).width() < 768){
                    $('.video-slick').slick({
                        slidesToScroll: 1,
                        arrows: false,
                        swipeToSlide: true,
                        dots: false,
                        responsive: [
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    arrows: true,
                                    dots: true
                                }
                            }
                        ]
                    });
                    videoSlicked = true;
                } else if($(window).width() >= 768){
                    if(videoSlicked){
                        $('.video-slick').slick('unslick');
                        videoSlicked = false;
                    }
                }
            }
            $(window).resize(function(){
                slickVideos();
            });
        }
        // CHI-54 hide decorative mobile menu svg from screen reader
        $('.menu-category.level-1 .icon').attr('aria-hidden', true);

        // init specific global components
        tagmanager.init(window.pageContext.ns);
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        searchplaceholder.init();
        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }
        $('.youtubePlayer').on('click', function (e) {
            e.preventDefault();
            var youtubeLink = $(this).attr("href");
            if( youtubeLink.match(/(youtube.com)/) ){
                var split_c = "v=";
                var split_n = 1;
            }

            if( youtubeLink.match(/(youtu.be)/) || youtubeLink.match(/(vimeo.com\/)+[0-9]/) ){
                var split_c = "/";
                var split_n = 3;
            }

            if( youtubeLink.match(/(vimeo.com\/)+[a-zA-Z]/) ){
                var split_c = "/";
                var split_n = 5;
            }

            var getYouTubeVideoID = youtubeLink.split(split_c)[split_n];

            var cleanVideoID = getYouTubeVideoID.replace(/(&)+(.*)/, "");

            if( youtubeLink.match(/(youtu.be)/) || youtubeLink.match(/(youtube.com)/) ){
                var videoEmbedLink = "https://www.youtube.com/embed/"+cleanVideoID+"?autoplay=1";
            }

            if( youtubeLink.match(/(vimeo.com\/)+[0-9]/) || youtubeLink.match(/(vimeo.com\/)+[a-zA-Z]/) ){
                var videoEmbedLink = "https://player.vimeo.com/video/"+cleanVideoID+"?autoplay=1";
            }

            $("body").append('<div class="YouTubePopUp-Wrap YouTubePopUp-animation"><div class="YouTubePopUp-Content"><span class="YouTubePopUp-Close"></span><iframe src="'+videoEmbedLink+'" allowfullscreen></iframe></div></div>');

            if( $('.YouTubePopUp-Wrap').hasClass('YouTubePopUp-animation') ){
                setTimeout(function() {
                    $('.YouTubePopUp-Wrap').removeClass("YouTubePopUp-animation");
                }, 600);
            }
            //close the youtube video by either clicking the close button or enter key if accessing via keyboard
            $(".YouTubePopUp-Wrap, .YouTubePopUp-Close").on('click keypress', function(){
                if(util.a11yClick(e) === true){
                    $('.YouTubePopUp-Wrap').remove();
                }
            });
            //add tabindex to content and close button to make them keyboard accessible and focus the close button on arrival
            $(".YouTubePopUp-Content, .YouTubePopUp-Close").attr('tabindex', '0');
            $(".YouTubePopUp-Close").focus();
        });
        $(document).keyup(function(e) {
            if ( e.keyCode == 27 ){
                $('.YouTubePopUp-Wrap, .YouTubePopUp-Close').click();
            }
        });
        //this will be used to close the video once we tab out of the iframe
        $('body').keyup(function(e) {
            if ( e.keyCode == 9 ){
                $('.YouTubePopUp-Wrap, .YouTubePopUp-Close').click();
            }
        });
        
        $('.serialNum input').on('keypress', function(event) {
        	var key = event.which || event.keyCode
    	    if ($(this).val().length <= 8 && key !== 8) {
    		    var current = $(this).val().trim();
    		    var currentCalc = current.replace(/\s/g, '');
    		    if (currentCalc.length % 2 == 0 && currentCalc.length > 1) {
    		    	$(this).val(current + ' ');
    		    }
    	    }
        });
        
        $('.registration input.phone').on('keypress', function(event) {
        	if (event.which < 48 || event.which > 57 || $(this).val().length >= 14) {
        		event.preventDefault();
        	} else {
                $(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3"));
        	}
        });
        
        $('.registration input.email').on('blur', function(event) {
            var enteredEmail = $(this).val();
            var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            var passFail = regex.test(enteredEmail);
            if (passFail == false && enteredEmail !== '') {
            	if ($('.wrongEmail').length < 1) {
     		        $(this).addClass('error');
     		        $(this).after("<span class='wrongEmail error'>Please Enter a Valid Email</span>");
     		        $('#sendBtn').attr('disabled', true);
     		        $('#sendBtn').css('pointer-events', 'none');
     		    }
            } else {
        		$(this).removeClass('error');
        		$('.wrongEmail').remove();
        		$('#sendBtn').attr('disabled', false);
        		$('#sendBtn').css('pointer-events', 'auto');
            }
        });

        var navbar = document.getElementById("stickyHeader");
        if (navbar) {
	        var sticky = navbar ? navbar.offsetTop : 0;
	        window.onscroll = function() {
	          if (window.pageYOffset >= (sticky+1)) {
	            navbar.classList.add("isSticky")
	          } else {
	            navbar.classList.remove("isSticky");
	          }
	        }
        }
    }
};
// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
	    app.init();
	    window.bvCallback = function (BV) {
	        if (typeof BV !== 'undefined' && BV.swat_reviews) {
	            BV.swat_reviews.on('show', function () {
	                // This line assumes SFRA collapsible panels and is called when clicking on the review summary
	            	$('#product-reviews-tab-container').click();
	            	$('.product-reviews-tab-mobile').addClass('active');
	            });
	        }
	    };
});

'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    util = require('../../util'),
    dialog = require('../../dialog'),
    progress = require('../../progress'),
    page = require('../../page');

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
}

function setCCFieldsDI(data) {
	// fill the form / clear the former cvn input
	var DIFrame = $("#DIeCommIFrame").contents();

    $("#Paymetric_CreditCardNumber", DIFrame).val(data.maskedNumber);

    $("#Paymetric_Exp_Month", DIFrame).val(data.expirationMonth);
    $("#Paymetric_Exp_Year", DIFrame).val(data.expirationYear.toString().substr(2,2));
    $("#Paymetric_CVV", DIFrame).val("");

    var elCCVal = $("#Paymetric_CreditCardType", DIFrame);

    if(data.type == "Amex") elCCVal.val("3000");
    else if(data.type == "Visa") elCCVal.val("4000");
    else if(data.type == "Master") elCCVal.val("5000");
    else if(data.type == "Discover") elCCVal.val("6000");

    $("table.DataIntercept label", DIFrame).each(function() {
        $(this).remove();
    });

}
/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
	// load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFieldsDI(data);	//This is used for DIECOMM
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method');
    $paymentMethods.removeClass('payment-method-expanded').find('.required').removeClass('suppress');

    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    $selectedPaymentMethod.addClass('payment-method-expanded');
    //suppress validation of hidden inputs in non-selected payment methods (BML)
    $paymentMethods.not($selectedPaymentMethod).find('.required').addClass('suppress');

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

    // hide continue button and disable billing address form when paypal is selected
    if (paymentMethodID === 'PayPal') {
        $('button[name$=_save]').hide();
        $('.same-as-shipping, .billing-address-set').find('input, select').each(function() {$(this).attr('disabled','disabled');});
        $("#same-as-shipping").prop("checked",true);
        $('#same-as-shipping').click();
    } else if (paymentMethodID === 'AFTERPAY_PBI') {
        formPrepare.validateForm(true);
        if ($('.checkout-half').hasClass('error')) {
            $('#is-CREDIT_CARD').trigger('click');
            $('.general-billing-error').text(Resources.BILLING_ADDRESS_INVALID);
        } else {
            $('.general-billing-error').text('');
        }
        $('button[name$=_save]').text(Resources.CHECKOUT_AFTERPAY_BUTTON)
        $('button[name$=_save]').show();
        $('.same-as-shipping, .billing-address-set').find('input, select').each(function() {$(this).removeAttr('disabled');});
    } else {
        formPrepare.validateForm();
        $('button[name$=_save]').text(Resources.CHECKOUT_BUTTON)
        $('button[name$=_save]').show();
        $('.same-as-shipping, .billing-address-set').find('input, select').each(function() {$(this).removeAttr('disabled');});
    }
}
function getAfterPayFrame() {
    var url = Urls.afterpayUrl;
    // token timeout is 30 minutes
    var frequency = 30 * 60 * 1000;

    // get access token. request new token every 30 minutes
    $.ajax({
        url: url,
        method: 'POST',
        dataType: 'json'
    }).done(function(data) {
        var currentdate = new Date();
        var datetime = "Time: " + currentdate.getHours().toString() + ":" + currentdate.getMinutes().toString() + ":" + currentdate.getSeconds().toString();

        if(!data.result) { console.log("getAfterPayFrame Response: null"); }
        if(!data.result != null) {
            var response = data.result;
            
            if(response.afterpayUrlResponseObj.afterpay_paymenturl != null && response.afterpayUrlResponseObj.afterpay_paymenturltoken != null) {
              //$('#afterpayurl').attr('data-url', response.afterpayUrlResponseObj.afterpay_paymenturl);
              window.location.replace(response.afterpayUrlResponseObj.afterpay_paymenturl);
                var token = setInterval(function() {
                    $.ajax({
                        url: url,
                        method: 'POST',
                        dataType: 'json'
                    }).done(function (data) {
                        var currentdate = new Date();
                        var datetime = "Time: " + currentdate.getHours().toString() + ":" + currentdate.getMinutes().toString() + ":" + currentdate.getSeconds().toString();

                        if(!data.result) { console.log("getAfterPayFrame Response: null"); }
                        if(!data.result != null) {
                            var response = data.result;
                            if(response.afterpayUrlResponseObj.afterpay_paymenturl != null && response.afterpayUrlResponseObj.afterpay_paymenturltoken != null) {
                                // $('#xiafterpay').attr('src', response.afterpayUrlResponseObj.afterpay_paymenturl);
//                                $('#afterpayurl').attr('data-url', response.afterpayUrlResponseObj.afterpay_paymenturl);
//                                var afterpayurl = $('#afterpayurl').attr('data-url');
//                                window.location.replace(afterpayurl);
                                window.location.replace(response.afterpayUrlResponseObj.afterpay_paymenturl);
                            }
                        }
                    });
                }, frequency);
            }
        }
    });
}
function getXiIframe() {
	var url = Urls.xiInterceptUrl;
	// token timeout is 30 minutes
	var frequency = 30 * 60 * 1000;

	// get access token. request new token every 30 minutes
	$.ajax({
		url: url,
		method: 'POST',
		dataType: 'json'
	}).done(function(data) {
		var currentdate = new Date();
        var datetime = "Time: " + currentdate.getHours().toString() + ":" + currentdate.getMinutes().toString() + ":" + currentdate.getSeconds().toString();

		if(!data.result) { console.log("XiIntercept Response: null"); }
		if(!data.result != null) {
			var response = data.result;
			//console.info("XiIntercept access token response: " + JSON.stringify(response));

			if(response.merchantGuid != null && response.accessToken != null && response.iframe != null) {
				$('#xiintercept').attr('src', response.iframe);

				// get new access token after it expires
				var token = setInterval(function() {
					$.ajax({
						url: url,
						method: 'POST',
						dataType: 'json'
					}).done(function (data) {
						var currentdate = new Date();
						var datetime = "Time: " + currentdate.getHours().toString() + ":" + currentdate.getMinutes().toString() + ":" + currentdate.getSeconds().toString();

						if(!data.result) { console.log("XiIntercept Response: null"); }
						if(!data.result != null) {
							var response = data.result;
							console.info("Xintercept access token response: " + JSON.stringify(response));

							if(response.merchantGuid != null && response.accessToken != null && response.iframe != null) {
								$('#xiintercept').attr('src', response.iframe);
							}
						}
					});
				}, frequency);
			}
		}
	});
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $checkoutForm = $('.checkout-billing');
    var $addGiftCert = $('#add-giftcert');
    var $giftCertCode = $('input[name$="_giftCertCode"]');
    var $addCoupon = $('#add-coupon');
    var $couponCode = $('input[name$="_couponCode"]');
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();

    //custom
    var $sameAsShipping = $('#same-as-shipping');
    var $enterNewAddress = $('#enter-new-address');
    var $sameAsShippingInput = $('input[name$="_sameAsShipping"]');
    var $billingAddressSet = $('.billing-address-set');

	var token = "";
	
    $('.promo-link').on('click', function() {
        $('.apply-promo').css('display', 'none');
        $('.coupon-collapse').css('display', 'block');
        $('.coupon-collapse').attr('aria-expanded', 'true');
    });
    
    $sameAsShipping.on('click', function(){
        var selectedAddress = $(this).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $checkoutForm);
        $billingAddressSet.addClass('hidden');
        $sameAsShippingInput.val(true);
    });

    $enterNewAddress.on('click', function(){
        util.clearAddressFields($checkoutForm);
        $billingAddressSet.removeClass('hidden');
        $sameAsShippingInput.val(false);
    });

    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });

    // default payment method to 'CREDIT_CARD'
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
    $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
        updatePaymentMethod($(this).val());
    });

    // select credit card from list
    $('#creditCardList').on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {return;}
        populateCreditCardForm(cardUUID);

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });

    $('#check-giftcert').on('click', function (e) {
        e.preventDefault();
        var $balance = $('.balance');
        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            var error = $balance.find('span.error');
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                return;
            }
            $balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance).removeClass('error').addClass('success');
        });
    });

    $addGiftCert.on('click', function (e) {
        e.preventDefault();
        var code = $giftCertCode.val(),
            $error = $checkoutForm.find('.giftcert-error');
        if (code.length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            } else {
                window.location.assign(Urls.billing);
            }
        });
    });

    $addCoupon.on('click', function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error'),
            $success = $checkoutForm.find('.redemption.coupon'),
            code = $couponCode.val();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                if ($error.hasClass('empty')) {
                    $error.removeClass('empty');
                }
                $error.html(msg);
                $success.html('');
                $success.addClass('empty');
                return;
            }

            if (data.success) {
                if ($success.hasClass('empty')) {
                    $success.removeClass('empty');
                }
                msg = '<span class="success">' + data.message + '</span>';
                $success.html(msg);
                $error.html('');
                $error.addClass('empty');
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success) {
                window.location.assign(Urls.billing);
            }
        });
    });

    // trigger events on enter
    $couponCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });
    $giftCertCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });

	$('[name$="billing_save"]').on('click',function(e){
		e.preventDefault();
		var curentPaymentMehotd = $('input[name="dwfrm_billing_paymentMethods_selectedPaymentMethodID"]:checked').val();
		if (curentPaymentMehotd === 'AFTERPAY_PBI') {
		    updatePaymentMethod('AFTERPAY_PBI');
		    var $form = $("form.address");
            var url = Urls.saveBillingAddress;
            progress.show($('.checkout-billing'));
            $.ajax({
                type: "POST",
                url: url,
                data: $form.serialize(),
                async: false
            }).done(function(response) {
                getAfterPayFrame();
            });
		} else if (curentPaymentMehotd === 'CREDIT_CARD') {
		    $XIFrame.submit({
	            iFrameId: 'xiintercept',
	            targetUrl: $('#xiintercept').attr('src'),

	            // passed validation and tokenization was successful
	            onSuccess: function(msg) {
	                var responseUrl = Urls.xiGetPayment;

	                $.ajax({
	                    url: responseUrl,
	                    method: 'POST',
	                    dataType: 'json'
	                }).success(function(response) {

	                    var $form = $("form.address");
	                    var url = Urls.continueBilling;
	                    $.ajax({
	                        type: "POST",
	                        url: url,
	                        data: $form.serialize(),
	                        async: false
	                    }).done(function(response) {
	                        var responseObj = $(response);
	                        var summaryPage = responseObj.find(".submit-order");
	                        
	                        // Redirect to the summary page if response contains the summary HTML
	                        if (summaryPage.length !== 0) {
	                            page.redirect(Urls.summaryStart);
	                        } else {
	                            getXiIframe();
	                        }
	                    });
	                }).error(function(response) {
	                    getXiIframe();
	                });
	            },
	            onError: function(msg) {
	                console.info("failed XiIntercept credit card auth");
	                getXiIframe();
	            }
	        });
		}
	});

	 if ($('.order-summary-footer').length == 0) {
		 // paymetric iframe
		getXiIframe();
		if (! (typeof $XIFrame === 'undefined') ) {
			$XIFrame.onload({
				iFrameId: 'xiintercept',
				targetUrl: $('xiintercept').attr('src'),
				autosizeheight: true,
				autosizewidth: true,
				onCardTypeChange: function(e) {},
				onValidate: function(e) {},
				onLoadSuccess: function(msg) {},
				onError: function(msg) {},
				onInvalidHandler: function(e) {}
			});
		}
	 }
};

'use strict';

var util = require('./util'),
    bonusProductsView = require('./bonus-products-view');


var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    init: function () {
        this.$el = $('#mini-cart');
        this.$content = this.$el.find('.mini-cart-content');

        // events
        this.$el.find('.mini-cart-total').on('mouseenter', function () {
            if (this.$content.not(':visible')) {
                this.slide();
            }
        }.bind(this));

        this.$content.on('mouseenter', function () {
            timer.clear();
        }).on('mouseleave', function () {
            timer.clear();
            timer.start(30, this.close.bind(this));
        }.bind(this));

        $('.mini-cart-close-btn').on('click', function() {
            $('.mini-cart-content').slideUp('fast');
        });
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        this.$el.html(html);
        util.scrollBrowser(0);
        this.init();
        this.slide();
        bonusProductsView.loadBonusOption();
        $('.mini-cart-link').focus();
        this.setFocus();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide: function () {
        timer.clear();

        // show the item
        this.$content.slideDown('slow');
        // after a time out automatically close it
        timer.start(6000, this.close.bind(this));
    },
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        timer.clear();
        this.$content.slideUp(delay);
    },
    /**
     * @function
     * @description Sets up the focus events for the mini cart
     */
    setFocus: function(){
        /**
         * @listener
         * @description Show the mini cart and focus on the mini cart header when the mini cart icon is focused or moused over
         */
        $('.mini-cart-link').on('focus mouseenter', function() {

            if (!$(this).parent().next('.mini-cart-content').is(':visible')) {
                $(this).parent().next('.mini-cart-content').show();
            }

            $('.mini-cart-header').focus();
            
            // hide the user menu when hovering over minicart
            var userInfo = $('.user-info.active');
            if (userInfo.length > 0) {
            	userInfo.toggleClass('active');
            }
        });

        /**
         * @listener
         * @description Set the aria-expanded value to false when the mini cart is hidden
         */
        $('.mini-cart-link-checkout').on('blur', function() {
            $('.mini-cart-content').hide();
            $('.mini-cart-link').attr('aria-expanded','false');
        });

        /**
         * @listener
         * @description Allow the user to exit out of the mini cart via keyboard via the escape key
         */
        $('.mini-cart-link').on('keyup', function(e) {
            if (e.keyCode == 27) {
                $(this).attr('aria-expanded','false');
                $(this).parent().next('.mini-cart-content').hide();
            }
        });
    }
};

module.exports = minicart;

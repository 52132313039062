'use strict';

var ajax = require('../../ajax'),
    tooltip = require('../../tooltip'),
    dialog = require('../../dialog'),
    util = require('../../util');

module.exports = function () {
    var $addToCart = $('#add-to-cart'),
        $addAllToCart = $('#add-all-to-cart'),
        $productSetItem = $('.product-set-item'),
        $productSetList = $('#product-set-list');

    var updateAddToCartButtons = function () {
        if ($productSetList.find('.add-to-cart[disabled]').length > 0) {
            $addAllToCart.attr('disabled', 'disabled');
            // product set does not have an add-to-cart button, but product bundle does
            $addToCart.attr('disabled', 'disabled');
        } else {
            $addAllToCart.removeAttr('disabled');
            $addToCart.removeAttr('disabled');
        }
    };

    if ($productSetList.length > 0) {
        // updateAddToCartButtons();
    }
    
    // Add find-retailer event for each set product
    $productSetItem.find('#find-retailer').on('click', function(e) {
    	e.preventDefault();
        dialog.open({
        	url: $(this).attr('href'),
            options: {
            	dialogClass: 'find-retailer'
            }
        });
    });
    
    // click on swatch for product set
    $productSetList.on('click', '.product-set-item .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        var url = Urls.getSetItem + this.search;
        var $container = $(this).closest('.product-set-item');
        var qty = $container.find('form input[name="Quantity"]').first().val();

        ajax.load({
            url: util.appendParamToURL(url, 'Quantity', isNaN(qty) ? '1' : qty),
            target: $container,
            callback: function () {
                updateAddToCartButtons();
                tooltip.init();
            }
        });
    });
};

'use strict';

var util = require('./util');

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
        syncGrids();
    });
    $('.icon.list-view').on('keydown', function (e) {
    	e.preventDefault()
        if (e.which == 13 || e.which == 32) {
        	if (!$('.search-result-content').hasClass('wide-tiles')) {
	            $('.search-result-content').toggleClass('wide-tiles');
	        	$('.toggle-grid').toggleClass('wide');
                $(this).attr('aria-selected', true);
                $('.icon.grid-view').attr('aria-selected', false);
	            syncGrids();
	            e.stopPropagation();
        	}
        }
        if (e.which == 39 || e.which == 37) {
            $('.icon.grid-view').focus();
        }
    });
    $('.icon.grid-view').on('keydown', function (e) {
    	e.preventDefault()
	    if (e.which == 13 || e.which == 32) {
	        if ($('.search-result-content').hasClass('wide-tiles')) {
	            $('.search-result-content').toggleClass('wide-tiles');
	        	$('.toggle-grid').toggleClass('wide');
                $(this).attr('aria-selected', true);
                $('.icon.list-view').attr('aria-selected', false);
	            syncGrids();
	            e.stopPropagation();
	        }
    	}
        if (e.which == 39 || e.which == 37) {
            $('.icon.list-view').focus();
        }
    });
}

/**
 * Define pieces size to sync
 */
function syncGrids () {
    var $tiles = $('.tiles-container .grid-tile');
    if (!($tiles.length === 0)) {
        var ww = $(window).width();
        if (ww > 480 && ww <= 1024) {
            syncChunk($tiles, 2)
        } else if (ww >= 1025) {
            syncChunk($tiles, 3)
        } else if (ww <= 480) {
            syncChunk($tiles, 1)
        }
    }
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    gridViewToggle();
    $('.swatch-list').on('mouseleave', function () {
        // Restore current thumb image
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $thumb.data('current');
        try {
            $thumb.attr({
                src: data.src,
                alt: data.alt,
                title: data.title
            })
        } catch(e) {
            return true;
        }
    });
    $('.swatch-list .swatch').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.product-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));

        var data = $(this).children('img').filter(':first').data('thumb');
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title
        };
        $thumb.attr(currentAttrs);
        $thumb.data('current', currentAttrs);
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $(this).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });

    $('.product-swatches-all').unbind('click').on('click', function() {
        $(this).toggleClass('active');
    });

    util.smartResize(function () {
        syncGrids();
    });

    syncGrids();
}
/**
 * Cut array into pieces and syncs them
 * @param $tiles
 * @param $portion
 */
function syncChunk($tiles, $portion) {
    for (var i = 0; i < $tiles.length; i += $portion) {
        var tilesArrayChunk = $tiles.slice(i, i + $portion);
        tilesArrayChunk.removeAttr('style').syncHeight();
    }
}

exports.init = function () {
    initializeEvents();
};

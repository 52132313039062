'use strict';

var address = require('./address'),
	login = require('../../login'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    shipping = require('./shipping');

/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    $('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');

    if ($('.gift-message-text').hasClass('hidden')) {
        $('.gift-message-text').attr('aria-hidden', 'true');
    } else {
        $('.gift-message-text').removeAttr('aria-hidden');
    }
}
/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    login.init();
    
    if ($('.checkout-shipping').length > 0) {
        shipping.init();
    } else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
    } else {
        billing.init();
    }

    //if on the order review page and there are products that are not available diable the submit order button
    if ($('.order-summary-footer').length > 0) {
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
        
        // Populate Paymetric Blackbox input field
		var bb_info = ioGetBlackbox(); 
		$('#ioBlackBox').val(bb_info.blackbox);
    }
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);
    giftMessageBox();
};

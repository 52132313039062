'use strict';

var dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    page = require('../../page'),
    util = require('../../util'),
    Promise = require('promise'),
    _ = require('lodash'),
    tagmanager = require('../../tagmanager');

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form, eventTime) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]'),
        $addSubProduct = $form.find('.add-sub-product'),
        $action = $form.find('input[name="cartAction"]'),
        $isAddToCartValid = $addSubProduct.find('#isAddToCartValid');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0 || $.trim($qty.val()).length === 0) {
        $qty.val('1');
    }

    // Check if set product is not available. If not or available only in-store don't add to cart.
    if ($addSubProduct.length === 1 && ($isAddToCartValid.val() === 'false' || $isAddToCartValid.length === 0)) {
        return;
    }
    
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize() + '&eventTime=' + eventTime
    })).then(function (response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
        	var newUrl;
            var currentUrl = window.location.href;
            if ($action.val() == 'update') {
                if (currentUrl.indexOf('Cart-SubmitForm') > 0) {
         	        currentUrl= currentUrl.slice(0,currentUrl.lastIndexOf('/'));
         	        currentUrl = currentUrl + '/Cart-Show';
                }
                newUrl = currentUrl;
                history.pushState({}, null, newUrl);
            }
            return response;
        }
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();
    var $form = $(this).closest('form');
    var eventTime = Math.round(Date.now() / 1000); // current time in seconds
    
    addItemToCart($form, eventTime).then(function (response) {
    	tagmanager.addToCart($.parseJSON($('.add-to-cart').attr('data-gtmdata')), $('.add-to-cart').parent().prev('div').find('[name=Quantity]').val(), eventTime);
        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass('sub-product-item')) {
                dialog.close();
            }
            minicart.show(response);
        }
    }.bind(this));
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    Promise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
        });
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function () {
    $('.add-to-cart').unbind('click', addToCart);
    $('.add-to-cart').bind('click', addToCart);
    $('#add-all-to-cart').on('click', addAllToCart);
};

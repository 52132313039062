
'use strict';

var ajax =  require('./ajax');
var util = require('./util');
var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    isPOBox: /(?:P(?:ost(?:al)?)?[.\-\s]*(?:(?:O(?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i,
    validEmail: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
};
// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    errorPlacement: function ($error, $element) {
        var $selectStyle = $element.parent('.select-style');
        if ($selectStyle.length) {
            $selectStyle.after($error);
        } else if ($element.attr('type') === 'checkbox' || $element.attr('type') === 'radio') {
            var $label = $element.next('label');

            if ($label.length) {
                $label.after($error);
            } else {
                $element.after($error);
            }
        } else {
            $element.after($error);
        }
    },
    highlight: function(element, errorClass, validClass) {
        if (element.type === 'radio') {
            this.findByName(element.name).addClass(errorClass).removeClass(validClass);
        } else {
            $(element).parents('.form-row').addClass(errorClass).removeClass(validClass);
            $(element).addClass(errorClass).removeClass(validClass);
        }
    },
    unhighlight: function(element, errorClass, validClass) {
        if (element.type === 'radio') {
            this.findByName(element.name).removeClass(errorClass).addClass(validClass);
        } else {
            $(element).parents('.form-row').removeClass(errorClass).addClass(validClass);
            $(element).removeClass(errorClass).addClass(validClass);
        }
    },
    ignore: '.suppress',
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
    }
};
/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var country = $(el).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.phone[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

var validateEmail = function (value, el) {
	var isOptional = this.optional(el);
    var isValid = regex.validEmail.test($.trim(value));
    return isOptional || isValid;
};

var validateManufactureDate = function (value) {
    var date_regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
    return date_regex.test(value);
};

var validateSerialNum = function (value, el) {
    var regex = /[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{4,5}/g;
    return regex.test(value);
};

var validateModelNum = function (value, el) {
    var regex = /[0-9]{14}/;
    return regex.test(value);
};

function getModelTypeJson(value) {
	var response;
	ajax.getJson({
	    url: util.appendParamsToUrl(Urls.modelexists, {
	        model: value
	    }),
	    async: false,
	    callback: function(data) {
	    	response = data;
	    }
	});
	return response;
}
	
var validateModelTypeGear = function (value, el) {
	var data = getModelTypeJson(value);
    if (data && data[0] == 'true') {
		if (data[2] === 'Gear') {
			$('.modelname input').val(data[1]);
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

var validateModelTypeTravel = function (value, el) {
	var data = getModelTypeJson(value);
    if (data && data[0] == 'true') {
		if (data[2] === 'Travel') {
			$('.modelname input').val(data[1]);
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

/**
 * @function
 * @description Validates a given postal code against the countries postal regex
 * @param {String} value The postal code which will be validated
 * @param {String} el The input field
 */
var validatePostal = function (value, el) {
    var country = $(el).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.postal[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

var validateNoPOBox = function (value, el) {
	if (el.closest('.billing-address-set') == null) {
        var isPOBOX = regex.isPOBox.test($.trim(value));
	}
    return !isPOBOX;
};

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Change default mail validation method of jQuery validation plugin.
 */
$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);

/**
 * Change default manufacture date validation method of jQuery validation plugin.
 */
$.validator.addMethod('manufactureDate', validateManufactureDate, Resources.VALIDATE_MANUFACTURE_DATE);

/**
 * Change default serialNum validation method of jQuery validation plugin.
 */
$.validator.addMethod('serialnum', validateSerialNum, 'Please Enter a Valid Serial Number');

$.validator.addMethod('modelnum', validateModelNum, 'Please Enter a Valid Model Number');

$.validator.addMethod('modelnumtravel', validateModelTypeTravel, 'Model is not Travel');

$.validator.addMethod('modelnumgear', validateModelTypeGear, 'Model is not Gear');

/**
 * Change default postal validation method of jQuery validation plugin.
 */
$.validator.addMethod('postal', validatePostal, Resources.VALIDATE_ZIPCODE);

/**
 * Change default address1 validation method of jQuery validation plugin.
 */
$.validator.addMethod('address1', validateNoPOBox, Resources.VALIDATE_POBOX);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * maxfilesize validation
 */
$.validator.addMethod("maxfilesize", function (value, element) {
    if (this.optional(element) || ! element.files || ! element.files[0]) {
        return true;
    } else {
        return element.files[0].size <= 1024 * 1024 * 4;
    }
}, Resources.MAX_FILE_SIZE);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    postal: Resources.VALIDATE_ZIPCODE,
    address1: Resources.VALIDATE_POBOX,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;

'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    recommendations = require('./recommendations'),
    variant = require('./variant'),
    imagesLoaded = require('imagesloaded');
var sliderLoaded = false;

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    recommendations();
    pdpFeaturesInit();
    $(window).on('featuresSliderLoaded', function() {
        pdpFeaturesInit();
    });
    tooltip.init();
}


function pdpFeaturesInit() {
    var $featuresSection = $('.pdp-features');
    var $hCarousel = $('.product-features');
    // do not initialize twice
    if ($hCarousel.hasClass('slick-slider')) {
        return;
    }
    sliderLoaded=false;
    imagesLoaded($featuresSection).on('always', function () {
    	if (!sliderLoaded) {
    		sliderLoaded=true;
	        $featuresSection.show();
	        $hCarousel.slick({
	            slidesToScroll: 1,
	            slide: '.feature-item',
	            slidesToShow: 4,
	            swipeToSlide: true,
	            responsive: [
	                {
	                    breakpoint: 768,
	                    settings: {
	                        slidesToShow: 1,
	                        dots: true,
	                        arrows: false
	                    }
	                }
	            ]
	        });
    	}
    });

};

function pdpVideoSliderInit() {
    var $hCarousel = $('.collection-video');
    // do not initialize twice
    if (!$hCarousel.hasClass('slick-slider')) {
        $hCarousel.slick({
            slidesToScroll: 1,
            slide: '.collection-video-wrapper',
            swipeToSlide: true,
            dots: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 9999,
                    settings: "unslick"
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });
        $hCarousel.slick('resize');
    }
    $('#slick-slide00').click();
};



/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');

    addToCart();
    availability();
    variant();
    image();
    productSet();

    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $('[data-action="wishlist"], [data-action="gift-registry"]').on('click', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $('.product-options select').on('change', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $('.thumbnail-link, .unselectable a').on('click', function (e) {
        e.preventDefault();
    });
    
    $('[data-action="return-policy"]').on('click', function (e) {
    	e.preventDefault();
    	dialog.open({
            url: $(e.target).attr('href')
        });
    });

    $('[data-action="return-policy"] svg.svg-returns-dims').on('click', function (e) {
    	e.preventDefault();
    	dialog.open({
            url: $(e.target).parent().attr('href')
        });
    });

    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    // product details tabs
    $('.nav-tabs button').on('click', function (e) {
        e.preventDefault();
        var $this = $(this),
            others = $this.closest('li').siblings().children('button'),
            target = $this.attr('href');
        others.parent().removeClass('active');
        $this.parent().addClass('active');
        $('.tab-content').children('div').hide();
        $(target).show();
    });
    
    //product details tabs
    $('.tab-drawer-heading').unbind();
    $('.tab-drawer-heading').on('click', function (e) {
       e.preventDefault();
        var $this = $(this),
            target = $this.attr('href');
        $this.toggleClass('active');
        $(target).slideToggle();
        if (target == '#product-video') {
            pdpVideoSliderInit();
        }
    });
    $('#find-retailer').on('click', function(e) {
        e.preventDefault();
        dialog.open({
            url: $(this).attr('href'),
            options: {
                dialogClass: 'find-retailer'
            }
        });
    });

    $('.promotion-see-details').on('click', function () { 
        dialog.open({
            html: $(this).parent().find('.tooltip-content').html(),
            options: {
                dialogClass: 'afterpay-modal'
            }
        });
    });
    
    $('.afterpay-learnmore').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('data-href')
        });
    });

    $('#backInStock-btn').on('click', function () {
        dialog.open({
            url: $(this).data('href')
        });
    });
}

$(document).on('ajaxStop', function (event, xhr, settings) {
    initializeDom();
    initializeEvents();
});

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
    }
};

setTimeout(function() {
    $('.slick-slide').each(function() {
        var slideAria = $(this).attr('aria-describedby');
	    if ($(slideAria).length < 1) {
		    $(this).removeAttr('aria-describedby');
	    }
    });
}, 1000);

module.exports = product;

'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    cartStoreInventory = require('../storeinventory/cart'),
    util = require('../util'),
    page = require('../page');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    $('#cart-table').on('click', '.item-edit-details a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    })
    .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    })
    .on('change', 'input[name$="_quantity"]', function () {
        var _value = $(this).val();
        if (_value == "" || !(_value.match(/^\d+$/))) {
            $(this).val(0);
        }
        $('#update-cart').click();
    })
    .on('input', 'input[name$="_quantity"]', function () {
        this.value = this.value.replace(/[^\d\.\-]/g,'');
    });

    $('.promo-link').on('click', function() {
        $('.apply-promo').css('display', 'none');
        $('.coupon-collapse').css('display', 'block');
        $('.coupon-collapse').attr('aria-expanded', 'true');
    });

    $('.promotion-see-details').on('click', function () { 
        dialog.open({
            html: $(this).parent().find('.tooltip-content').html(),
            options: {
                dialogClass: 'afterpay-modal'
            }
        });
    });

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });

    $('button[name$="deleteBundle"]').on('click', function (e) {
    	e.preventDefault();
        var uuid = $(this).data('uuid');
        var url = util.appendParamsToUrl(Urls.deleteBundle, {uuid: uuid});
        // make the server call
        $.ajax({
            type: 'POST',
            url: url
        })
        .done(function () {
            // success
            page.refresh();
        })
    });
    
    $('.login-box').on('click', '.sign-drawer, .form-toggler', function (e) {
        e.stopPropagation();
        $('.login-form-holder').slideToggle();
    });
}

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
};

'use strict';

/**
 * @private
 * @function
 * @description Binds event to the place holder (.blur)
 */
function initializeEvents() {
    $('#q').focus(function () {
        var input = $(this);
        if (input.val() === input.attr('placeholder')) {
            input.val('');
        }
    })
    .blur(function () {
        var input = $(this);
        if (input.val() === '' || input.val() === input.attr('placeholder')) {
            input.val(input.attr('placeholder'));
        }
    })
    .blur();
    
    $('#qm').focus(function () {
        var input = $(this);
        if (input.val() === input.attr('placeholder')) {
            input.val('');
        }
    })
    .blur(function () {
        var input = $(this);
        if (input.val() === '' || input.val() === input.attr('placeholder')) {
            input.val(input.attr('placeholder'));
        }
    })
    .blur();
}

/**
 * Prevents the user from submitting the search while 
 * the search input contains placeholder text
 * 
 * @var     obj     searchForm
 * @var     obj     input
 * @var     string  placeholder
 * @param   obj     args
 * @return  VOID
 */
function preventEmptySearch(args) {
    var searchForm = $('[name="' + args.formName + '"]');
    // Search button click event handler
    searchForm.find('button').on('click', function(e) {
        var input = $(args.inputID);
        var placeholder = input.attr(args.placeHolderAttr);

        // If search text is placeholder text stop form submission
        if (input.val() === placeholder) {
            e.preventDefault();
        }
    });

    return;
}

exports.init = function() {
    initializeEvents();
    preventEmptySearch({
        formName: 'simpleSearch',
        inputID: '#q',
        placeHolderAttr: 'placeholder'
    });
    preventEmptySearch({
        formName: 'simpleSearchMobile',
        inputID: '#qm',
        placeHolderAttr: 'placeholder'
    });
}

'use strict';

/**
 * @private
 * @function
 * @description init events for the form error summary
 */
var errorSummaryCustomMsg = {
    init: function () {
        createErrorSummary();
        // this structure allows for custom error messages or events to be added as required

    }
};

function createErrorSummary() {
    if ($('.error-message').length > 0){
        var $form = $('.error-message').closest('form');
        var $summaryEl = $('<div role="alert"></div>');
        var $ul = $('<ul id="error-summary" style="text-decoration: underline"></ul>');
        var $h4 = $('<h4>'+Resources.VALIDATE_THERE_ARE+' '+$('.error-message').length+' '+Resources.VALIDATE_ERRORS_IN_FORM+'</h4>');
        // loop through error messages and copy to summary
        $('.error-message').each(function(){
            var text = $(this).text();
            var $input = $(this).parent().find('.field-wrapper input');
            var id = $input.attr('id');
            // bind event handler to li
            var $li = $('<li for="' + id + '" generated="true" class="error"><a href="#'+id+'">'+text+'</a></li>').on('click', function(e){
                e.preventDefault();
                $('html,body').animate({
                    scrollTop: $input.offset().top - 60
                }, 0);
                $input.focus();
            });
            $ul.append($li);
        });
        $summaryEl.prepend($h4, $ul);
        $form.prepend($summaryEl);
    }
}

module.exports = errorSummaryCustomMsg;
